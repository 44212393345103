import JSONParser from '../helper/json-parser';
import Landmark from '../components/landmark-adapter';
import {emailFormatValidator as validateEmailFormat} from '../helper/validation/email-validator';
import JshModule from '@core/scripts/helper/jsh-module';


const {moduleScope} = JshModule('newsletter');
const submitButtonNode = moduleScope.querySelector('[jsh-newsletter__submit]');
const submitButtonWrapperNode = moduleScope.querySelector('[jsh-newsletter__button-wrapper]');

const inputGroupNode = moduleScope.querySelector('[jsh-input__wrapper]');
const inputFieldNode = moduleScope.querySelector('[jsh-input]');
const landmark = JSONParser.tryParseJSON(submitButtonWrapperNode.getAttribute('jsh-newsletter__button-wrapper'));

submitButtonNode.addEventListener('click', submitNewsletterSignUp);
inputFieldNode.addEventListener('input', resetErrorHandling);

function submitNewsletterSignUp(event) {
    const inputValue = inputFieldNode.value;
    if (validateEmailFormat(inputValue)) {
        Landmark.sendLandmark(this.landmark);
    } else {
        event.preventDefault();
        inputGroupNode.classList.add('is-error');
    }
}

function resetErrorHandling() {
    inputGroupNode.classList.remove('is-error');
}
